import logo from '@assets/Logo.jpg'
import text from '@assets/ComingSoonText.jpg'
import '@css/ComingSoon.css'
import '@css/Fade.css'
import React from 'react'

function ComingSoon() {
  return (
    <div className="comingSoonPage fadeIn">
      <a
        className="headerGroup"
        href="https://www.etsy.com/uk/shop/HarukiHouseShop"
      >
        <img className="logo" src={logo} />
        <img className="text" src={text} />
      </a>
    </div>
  )
}

export default ComingSoon
